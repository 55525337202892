import React from "react"
import Breadcrumbs from '../breadcrumbs'


const About = () => {
  return (
    <section className="about-seleccion">
      <div className="container-fluid">
      
        <div className="row">
          <div className="col-12 text-center">
            <Breadcrumbs
              props={[
                { name: 'Bullrich Campos', route: "/", location: "" },
                { name: "Selección", route: "", location: "" },
              ]}
            />
            <p>
              <div className="first">E</div>n Bullrich Campos llevamos a cabo un
              riguroso proceso de selección y tasación, basado en nuestra amplia
              experiencia y el conocimiento actualizado del mercado inmobiliario
              rural. Este análisis exhaustivo nos permite ofrecer las mejores
              opciones de terrenos a nuestros clientes. Con nuestro compromiso
              por brindar un servicio de calidad y confianza, estamos seguros de
              que encontraremos el terreno ideal para cumplir con las distintas
              necesidades de nuestros clientes.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
