import React from "react"
import Image from "../../../images/main-seleccion.webp"
import BannerTop from "../../v1/Global/Modules/MediaPush/BannerTop"
const Main = () => {

  // <BannerTop page={"Ini"}/>


  return (
    <section className="main-seleccion banner-top">
      <BannerTop page={"EMP"}/>
      <img src={Image} alt="Imagen de portada seleccion" />
      <h1 className="white">Propiedades con <br /> cualidades únicas</h1>
    </section>
  )
}

export default Main
