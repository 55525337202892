import React, { useEffect, useRef, useState } from "react"
import Select from "react-select"
import makeAnimated from "react-select/animated"
import DevelopmentCard from "../card-develpments"
import { connect } from "react-redux"
import { getDevelopmentsByFilters } from "../../../helpers/helper.developments"

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
]

const Developments = ({ developments, filtersDevelopment, loading }) => {
  const animatedComponents = makeAnimated()

  const [filters, setFilters] = useState({
    locations: [],
    customs: [],
  })

  const getCustomsOptions = customs => {
    return customs.map(custom => ({ value: custom.id, label: custom.name }))
  }

  const getLocationsOptions = locations => {
    return locations.map(location => ({ value: location, label: location }))
  }

  const handleCustomsChange = e => {
    setFilters({ ...filters, customs: e.map(({ value }) => value) })
  }
  const handleLocationChange = e => {
    setFilters({ ...filters, locations: e.map(({ value }) => value) })
  }

  return !loading ? (
    <section className="developments-seleccion pt-0">
      <div className="container-fluid">
        <h2 className="text-center mb-lg-5 mb-4">Nuestra selección</h2>
        <div className="row">
          <div className="col-12"></div>
          <div className="col-lg-12 mb-lg-4 mt-lg-2 mt-1">
            <div className="row">
              <div className="col-lg-6 pe-lg-4 d-flex flex-column flex-lg-row">
                <div className="container-select">
                  <span className="mb-lg-2 d-block">Filtrar por una o más aptitudes:</span>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={handleCustomsChange}
                    noOptionsMessage={() => "No se encontraron aptitudes"}
                    placeholder={"Seleccionar..."}
                    options={getCustomsOptions(filtersDevelopment.customs)}
                    className="react-select-container mt-3 mt-lg-0 me-lg-3 high-index"
                    classNamePrefix="react-select"
                  />
                </div>
                <div className="container-select d-none mt-4 mt-lg-0 mb-0">
                  <span className="mb-lg-2 d-block">Filtrar por una o más ubicaciones:</span>
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    isMulti
                    onChange={handleLocationChange}
                    placeholder={"Seleccionar..."}
                    options={getLocationsOptions(filtersDevelopment.locations)}
                    noOptionsMessage={() => "No se encontraron ubicaciones"}
                    className="react-select-container w-100 mt-3 mt-lg-0"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mt-lg-2 mt-5">
            <div className="row">
              {getDevelopmentsByFilters(developments?.data?.objects, 
                filters).sort((dev1, dev2) => dev2?.id - dev1?.id).map((d, i) => (
                <div
                  className={
                    "col-lg-6 mb-lg-5 mb-4 " +
                    (i % 2 === 0 ? "pe-lg-4" : "ps-lg-4")
                  }
                >
                  <DevelopmentCard aos={"fade-up"} d={d} i={i} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    ""
  )
}
export default connect(
  state => ({
    developments: state.developments.developments,
    filtersDevelopment: state.developments.filtersDevelopment,
    loading: state.developments.loading,
  }),
  null
)(Developments)
